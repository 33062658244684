.App {
  text-align: center;
}



.header{
  color: #000; 
  text-align:'start'; 
  font-weight: 500;
  font-size: 28px;
}

.sub-header{
  color:gray;
  font-size: 12px; 
  text-align: start;
}

.tab-container {
  margin-bottom: 30;
  display:flex;
  flex-direction: row;
  justify-content: center;
}

.tab-selected {
  background-color: #000;
  margin-left: 10;
  color:#FFF;
  padding: 12px;
  padding-right: 100px;
  outline: none;
}

.tab-unselected  {
  background-color: #EEE;
  padding: 12px;
  padding-right: 100px;
  outline: none;
  display: flex;
  color: darkgray;
}

.ternary-header{
  color: #000;
  text-align: start;
  font-weight: 600;

}

.scan-button {
  height: 50px;
  background-color: blue;
  color: white;
  padding: 10px;
  padding-right: 100px;
  margin-top: 18px;
  outline: none;
  border: none;
}

.ternary-sub-header{
  color: #333;
  font-size: 12;
  font-weight: 500;
  text-align:start;
}

.image-container{
  background-color: #DDD;
  padding-top: 4px;
  padding-bottom: 4px;
  flex-direction: row;
  display: flex;
}

.image-label {
  color: #000;
  font-weight: 400;
  text-align: center;
  flex: 1;
  text-align:left;
  margin-left: 12px;
  font-weight: 500;
  margin-right: 12px;
}
.close-icon {
  width: 20px;
  height: 20px;
  align-self: center;
  margin-right: 12px;
}

.upload-color {
  color: blue;
  font-weight: 600;
  margin: 30px;
  font-size: 18px;
}

.result-container {
  border-width: 0.5px;
  border-style: solid;
  width: 90%;
  border-color: gray;
  margin-top: 5%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  
  /* background-color: #000; */
  
}

.root-container {
  width:100%,
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
